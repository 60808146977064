import React, { Component } from "react";
import { navigate } from "gatsby";
import Layout from "../layouts/en";
import SEO from "../components/seo";
import langData from "../data/static-pages/pages/verify-account/en";
import VerifyAccount from "../components/TWComponents/VerifyAccount";
export default class VerifyAccountPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.params = props.location.search.split("?email=");
    this.email = this.params[1] !== undefined ? this.params[1] : "";
  }

  componentDidMount() {
    navigate(`/verify-account/?email=${this.email}`);
  }

  render() {
    return (
      <Layout location={this.props.location} footer_margin={true}>
        <SEO
          title="Verify Your Account "
          keyword="Verify Your Account"
          decription="Verify Your Account"
        />
        <VerifyAccount
          title={langData.title}
          description={`${langData.description_1} ${this.email} ${langData.description_2}`}
          lang={"en"}
          continueTitle={langData.continue}
        />
      </Layout>
    );
  }
}
